var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-top": "-65px" } },
    [
      _c("div", [
        _c(
          "h3",
          {
            staticClass: "tit-group-bg mt-4",
            staticStyle: { color: "rgba(0, 0, 0, 0.6)", "line-height": "1" }
          },
          [_vm._v(_vm._s(_vm.$t("Login Setting")))]
        )
      ]),
      _c(
        "v-row",
        [
          _c("v-col", { staticStyle: { "max-width": "30%" } }, [
            _c("div", { staticStyle: { "padding-left": "20px" } }, [
              _c("p", { staticClass: "label-txt" }, [
                _vm._v(_vm._s(_vm.$t("Login Session")))
              ]),
              _c("br"),
              _c(
                "div",
                {
                  staticStyle: {
                    "justify-content": "normal",
                    display: "inline-flex"
                  }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-select", {
                        staticClass: "form-select ml-3",
                        staticStyle: { width: "110px" },
                        attrs: {
                          items: _vm.items,
                          "item-text": "txt",
                          "item-value": "val",
                          placeholder: _vm.items[0].txt,
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          solo: "",
                          "return-object": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "span",
                                  { staticStyle: { "font-size": "0.8125rem" } },
                                  [_vm._v(_vm._s(_vm.$t(item.txt)))]
                                )
                              ]
                            }
                          },
                          {
                            key: "selection",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "LedPatternClass",
                                    staticStyle: {
                                      display: "contents !important"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t(item.txt)))]
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.LoginSessionselected,
                          callback: function($$v) {
                            _vm.LoginSessionselected = $$v
                          },
                          expression: "LoginSessionselected"
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "form-input appendIcon",
                        staticStyle: {
                          "max-width": "88px",
                          "margin-left": "10px"
                        },
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          solo: "",
                          type: "text",
                          clearable: ""
                        },
                        on: { keydown: _vm.clearTabindex },
                        model: {
                          value: _vm.SessionTimeout,
                          callback: function($$v) {
                            _vm.SessionTimeout = $$v
                          },
                          expression: "SessionTimeout"
                        }
                      }),
                      _c("span", [
                        _c(
                          "p",
                          {
                            staticClass: "label-txt",
                            staticStyle: {
                              "font-weight": "bold",
                              "margin-left": "12px",
                              "margin-top": "10px"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("seconds")) + " ")]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _c("v-col", [
            _c("div", [
              _c("p", { staticClass: "label-txt" }, [
                _vm._v(_vm._s(_vm.$t("Password Rules")))
              ]),
              _c("br"),
              _c(
                "div",
                {
                  staticStyle: {
                    "justify-content": "normal",
                    display: "inline-flex"
                  }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-select", {
                        staticClass: "form-select ml-3",
                        staticStyle: { width: "110px" },
                        attrs: {
                          items: _vm.items,
                          "item-text": "txt",
                          "item-value": "val",
                          placeholder: _vm.items[0].txt,
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          solo: "",
                          "return-object": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "span",
                                  { staticStyle: { "font-size": "0.8125rem" } },
                                  [_vm._v(_vm._s(_vm.$t(item.txt)))]
                                )
                              ]
                            }
                          },
                          {
                            key: "selection",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "LedPatternClass",
                                    staticStyle: {
                                      display: "contents !important"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t(item.txt)))]
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.PasswordRulesselected,
                          callback: function($$v) {
                            _vm.PasswordRulesselected = $$v
                          },
                          expression: "PasswordRulesselected"
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "form-input appendIcon",
                        staticStyle: {
                          "max-width": "88px",
                          "margin-left": "10px"
                        },
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          solo: "",
                          type: "text",
                          clearable: ""
                        },
                        on: { keydown: _vm.clearTabindex },
                        model: {
                          value: _vm.PwPolicyMinimumLength,
                          callback: function($$v) {
                            _vm.PwPolicyMinimumLength = $$v
                          },
                          expression: "PwPolicyMinimumLength"
                        }
                      }),
                      _c("span", [
                        _c(
                          "p",
                          {
                            staticClass: "label-txt",
                            staticStyle: {
                              "margin-left": "12px",
                              color: "red",
                              "margin-top": "10px"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "to 20 Characters, Contains at least one each of letters, uppercase , digit, symbols, cannot contains consecutive characters"
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c(
            "v-btn",
            {
              staticClass: "btn-s ml-2",
              attrs: { disabled: _vm.btnDisabledDashboardSettings, text: "" },
              on: { click: _vm.Toeditpropertyvalueloginsetting }
            },
            [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
          )
        ],
        1
      ),
      _c("br"),
      _c(
        "div",
        [
          _c(
            "v-row",
            [
              _c("v-col", {}, [
                _c(
                  "div",
                  [
                    _c("div", [
                      _c(
                        "h3",
                        {
                          staticClass: "tit-group-bg",
                          staticStyle: {
                            color: "rgba(0, 0, 0, 0.6)",
                            "line-height": "1"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Update History Data")))]
                      )
                    ]),
                    _c(
                      "v-row",
                      [
                        _c("v-col", {}, [
                          _c(
                            "div",
                            { staticStyle: { "padding-left": "20px" } },
                            [
                              _c("p", { staticClass: "label-txt" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("Update History Delete Cycle")
                                    ) +
                                    " "
                                )
                              ]),
                              _c("br"),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "justify-content": "normal",
                                    display: "inline-flex"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-text-field", {
                                        staticClass: "form-input appendIcon",
                                        staticStyle: {
                                          "max-width": "88px",
                                          "margin-left": "10px"
                                        },
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          "hide-details": "",
                                          solo: "",
                                          type: "text",
                                          clearable: ""
                                        },
                                        on: { keydown: _vm.clearTabindex },
                                        model: {
                                          value: _vm.batchPurgeRetentionDays,
                                          callback: function($$v) {
                                            _vm.batchPurgeRetentionDays = $$v
                                          },
                                          expression: "batchPurgeRetentionDays"
                                        }
                                      }),
                                      _c("span", [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "label-txt",
                                            staticStyle: {
                                              "font-weight": "bold",
                                              "margin-left": "12px",
                                              "margin-top": "10px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.$t("days")) + " "
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]),
                        _c("v-col", [
                          _c("div", [
                            _c("p", { staticClass: "label-txt" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("Update History Delete Time")) +
                                  " "
                              )
                            ]),
                            _c("br"),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "justify-content": "normal",
                                  display: "inline-flex"
                                }
                              },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c("v-select", {
                                      staticClass: "form-select ml-3",
                                      staticStyle: { width: "110px" },
                                      attrs: {
                                        items: _vm.updatehistorytimeinhours,
                                        "item-text": "txt",
                                        "item-value": "val",
                                        placeholder:
                                          _vm.updatehistorytimeinhours[0].txt,
                                        outlined: "",
                                        dense: "",
                                        "hide-details": "",
                                        solo: "",
                                        "return-object": ""
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "item",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "0.8125rem"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t(item.txt))
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "selection",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "LedPatternClass",
                                                  staticStyle: {
                                                    display:
                                                      "contents !important"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t(item.txt))
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value: _vm.updatehistorydeletehours,
                                        callback: function($$v) {
                                          _vm.updatehistorydeletehours = $$v
                                        },
                                        expression: "updatehistorydeletehours"
                                      }
                                    }),
                                    _c("span", [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "label-txt",
                                          staticStyle: {
                                            "font-weight": "bold",
                                            "margin-left": "12px",
                                            "margin-top": "10px"
                                          }
                                        },
                                        [_vm._v(" : ")]
                                      )
                                    ]),
                                    _c("v-select", {
                                      staticClass: "form-select ml-3",
                                      staticStyle: { width: "84px" },
                                      attrs: {
                                        items: _vm.updatehistorytimeinminutes,
                                        "item-text": "txt",
                                        "item-value": "val",
                                        placeholder:
                                          _vm.updatehistorytimeinminutes[0].txt,
                                        outlined: "",
                                        dense: "",
                                        "hide-details": "",
                                        solo: "",
                                        "return-object": ""
                                      },
                                      model: {
                                        value: _vm.updatehistorydeleteminutes,
                                        callback: function($$v) {
                                          _vm.updatehistorydeleteminutes = $$v
                                        },
                                        expression: "updatehistorydeleteminutes"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-5" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn-s ml-2",
                        attrs: {
                          disabled: _vm.btnDisabledDashboardSettings,
                          text: ""
                        },
                        on: { click: _vm.Toeditpropertyvalueupdatehistory }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
                    )
                  ],
                  1
                )
              ]),
              _c("v-col", [
                _c(
                  "div",
                  [
                    _c("div", [
                      _c(
                        "h3",
                        {
                          staticClass: "tit-group-bg",
                          staticStyle: {
                            color: "rgba(0, 0, 0, 0.6)",
                            "line-height": "1"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Function Approval")))]
                      )
                    ]),
                    _c("v-col", [
                      _c("div", [
                        _c("p", { staticClass: "label-txt" }, [
                          _vm._v(_vm._s(_vm.$t("Template Mapping")))
                        ]),
                        _c("br"),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "justify-content": "normal",
                              display: "inline-flex"
                            }
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c("v-select", {
                                  staticClass: "form-select ml-3",
                                  staticStyle: { width: "84px" },
                                  attrs: {
                                    items: _vm.items,
                                    "item-text": "txt",
                                    "item-value": "val",
                                    placeholder: _vm.items[0].txt,
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "",
                                    solo: "",
                                    "return-object": ""
                                  },
                                  model: {
                                    value: _vm.TemplateMappingselected,
                                    callback: function($$v) {
                                      _vm.TemplateMappingselected = $$v
                                    },
                                    expression: "TemplateMappingselected"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "mt-5" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-s ml-2",
                            attrs: {
                              disabled: _vm.btnDisabledDashboardSettings,
                              text: ""
                            },
                            on: {
                              click: _vm.Toeditpropertyvaluefunctionapproval
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("familylinkSettings"),
      _c("SoftwareImageCustom"),
      _c("dashboardlanguagepart"),
      _c("dashboardmasterdatapart", {
        attrs: { Templatemap: _vm.templatevalue }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }