<template>
  <div style="margin-top: -65px;">
    <div>
      <!-- <h4 class="tit-section-sub" style="background: #e2e2e2 !important">
        {{ $t("Login Setting") }}
      </h4> -->
         <h3 class="tit-group-bg mt-4" style="color: rgba(0, 0, 0, 0.6);line-height: 1;">{{$t('Login Setting')}}</h3>

    </div>
    <v-row>
      <v-col style="max-width: 30%">
        <div style="padding-left: 20px">
          <p class="label-txt">{{ $t("Login Session") }}</p>
          <br />
          <div class="" style="justify-content: normal; display: inline-flex">
            <v-row>
              <v-select
                :items="items"
                item-text="txt"
                item-value="val"
                v-model="LoginSessionselected"
                :placeholder="items[0].txt"
                class="form-select ml-3"
                outlined
                dense
                style="width: 110px"
                hide-details
                solo
                return-object
              >
              <template v-slot:item="{item }">
                <span style="font-size: 0.8125rem;">{{ $t(item.txt) }}</span>
              </template>
              <template v-slot:selection="{item }">
                <span class="LedPatternClass" style="display: contents !important;">{{ $t(item.txt) }}</span>
              </template>
              </v-select>
              <v-text-field
                outlined
                dense
                hide-details
                solo
                v-model="SessionTimeout"
                class="form-input appendIcon"
                type="text"
                style="max-width: 88px; margin-left: 10px"
                clearable
                @keydown="clearTabindex"
              ></v-text-field>

              <span
                ><p
                  class="label-txt"
                  style="font-weight: bold; margin-left: 12px; margin-top: 10px"
                >
                {{ $t("seconds") }}
                </p></span
              >
            </v-row>
          </div>
        </div>
      </v-col>
      <v-col>
        <div>
          <p class="label-txt">{{ $t("Password Rules") }}</p>
          <br />
          <div class="" style="justify-content: normal; display: inline-flex">
            <v-row>
              <v-select
                :items="items"
                item-text="txt"
                item-value="val"
                v-model="PasswordRulesselected"
                :placeholder="items[0].txt"
                class="form-select ml-3"
                outlined
                dense
                style="width: 110px"
                hide-details
                solo
                return-object
              >
              <template v-slot:item="{item }">
                <span style="font-size: 0.8125rem;">{{ $t(item.txt) }}</span>
              </template>
              <template v-slot:selection="{item }">
                <span class="LedPatternClass" style="display: contents !important;">{{ $t(item.txt) }}</span>
              </template>
              </v-select>

              <v-text-field
                outlined
                dense
                hide-details
                solo
                v-model="PwPolicyMinimumLength"
                class="form-input appendIcon"
                type="text"
                style="max-width: 88px; margin-left: 10px"
                clearable
                @keydown="clearTabindex"
              ></v-text-field>
              <span
                ><p
                  class="label-txt"
                  style="
                    margin-left: 12px;
                    color: red;
                    margin-top: 10px;
                  "
                >
                {{ $t("to 20 Characters, Contains at least one each of letters, uppercase , digit, symbols, cannot contains consecutive characters") }}

                </p></span
              >
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="mt-5">
      <v-btn :disabled="btnDisabledDashboardSettings" text @click="Toeditpropertyvalueloginsetting" class="btn-s ml-2">
        {{ $t("Save") }}
      </v-btn>
    </div>
    <br />
    <div>
      <v-row>
        <v-col style="">
          <div>
            <div>
              <!-- <h4
                class="tit-section-sub"
                style="background: #e2e2e2 !important"
              >
                {{ $t("Update History Data") }}
              </h4> -->
         <h3 class="tit-group-bg" style="color: rgba(0, 0, 0, 0.6);line-height: 1;">{{$t('Update History Data')}}</h3>
            </div>
            <v-row>
              <v-col style="">
                <div style="padding-left: 20px">
                  <p class="label-txt">
                    {{ $t("Update History Delete Cycle") }}
                  </p>
                  <br />

                  <div
                    class=""
                    style="justify-content: normal; display: inline-flex"
                  >
                    <v-row>
                      <v-text-field
                        outlined
                        dense
                        hide-details
                        solo
                        v-model="batchPurgeRetentionDays"
                        class="form-input appendIcon"
                        type="text"
                        style="max-width: 88px; margin-left: 10px"
                        clearable
                        @keydown="clearTabindex"
                      ></v-text-field>

                      <span
                        ><p
                          class="label-txt"
                          style="
                            font-weight: bold;
                            margin-left: 12px;
                            margin-top: 10px;
                          "
                        >
                {{ $t("days") }}

                        </p></span
                      >
                    </v-row>
                  </div>
                </div>
              </v-col>
              <v-col>
                <div>
                  <p class="label-txt">
                    {{ $t("Update History Delete Time") }}
                  </p>
                  <br />

                  <div
                    class=""
                    style="justify-content: normal; display: inline-flex"
                  >
                    <v-row>
                      <v-select
                        :items="updatehistorytimeinhours"
                        item-text="txt"
                        item-value="val"
                        v-model="updatehistorydeletehours"
                        :placeholder="updatehistorytimeinhours[0].txt"
                        class="form-select ml-3"
                        outlined
                        dense
                        style="width: 110px"
                        hide-details
                        solo
                        return-object
                      >
                      <template v-slot:item="{item }">
                         <span style="font-size: 0.8125rem;">{{ $t(item.txt) }}</span>
                      </template>
                      <template v-slot:selection="{item }">
                        <span class="LedPatternClass" style="display: contents !important;">{{ $t(item.txt) }}</span>
                      </template>
                      </v-select>
                      <span
                        ><p
                          class="label-txt"
                          style="
                            font-weight: bold;
                            margin-left: 12px;
                            margin-top: 10px;
                          "
                        >
                          :
                        </p></span
                      >
                      <v-select
                        :items="updatehistorytimeinminutes"
                        item-text="txt"
                        item-value="val"
                        v-model="updatehistorydeleteminutes"
                        :placeholder="updatehistorytimeinminutes[0].txt"
                        class="form-select ml-3"
                        outlined
                        dense
                        style="width: 84px"
                        hide-details
                        solo
                        return-object
                      >
                      </v-select>
                    </v-row>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="mt-5">
            <v-btn :disabled="btnDisabledDashboardSettings" class="btn-s ml-2" @click="Toeditpropertyvalueupdatehistory" text>
              {{ $t("Save") }}
            </v-btn>
          </div>
        </v-col>

        <v-col>
          <div>
            <div>
              <!-- <h4
                class="tit-section-sub"
                style="background: #e2e2e2 !important"
              >
                {{ $t("Function Approval") }}
              </h4> -->
         <h3 class="tit-group-bg" style="color: rgba(0, 0, 0, 0.6);line-height: 1;">{{$t('Function Approval')}}</h3>

            </div>

            <v-col>
              <div>
                <p class="label-txt">{{ $t("Template Mapping") }}</p>
                <br />

                <div
                  class=""
                  style="justify-content: normal; display: inline-flex"
                >
                  <v-row>
                    <v-select
                      :items="items"
                      item-text="txt"
                      item-value="val"
                      v-model="TemplateMappingselected"
                      :placeholder="items[0].txt"
                      class="form-select ml-3"
                      outlined
                      dense
                      style="width: 84px"
                      hide-details
                      solo
                      return-object
                    >
                    </v-select>
                  </v-row>
                </div>
              </div>
            </v-col>
            <div class="mt-5">
              <v-btn :disabled="btnDisabledDashboardSettings" class="btn-s ml-2" @click="Toeditpropertyvaluefunctionapproval" text>
                {{ $t("Save") }}
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <familylinkSettings></familylinkSettings>
    <SoftwareImageCustom></SoftwareImageCustom>
    <dashboardlanguagepart></dashboardlanguagepart>
    <dashboardmasterdatapart :Templatemap='templatevalue'></dashboardmasterdatapart>
  </div>
</template>

<script>
import EventBus from '@/plugins/eventBus'
import Dashboardlanguage from './DashboardLanguage.vue'
import DashboardMasterdata from './DashboardMasterdata.vue'
import SoftwareImageCustom from './SoftwareImageCustom.vue'
import familylinkSettings from './familylinkSettings.vue'
const requests = {

  updateAccessMenu: {
    method: 'put',
    url: '/api/common/accessLevel/menu'
  }

}
export default {
  components: {
    dashboardlanguagepart: Dashboardlanguage,
    dashboardmasterdatapart: DashboardMasterdata,
    SoftwareImageCustom,
    familylinkSettings

  },
  data () {
    const items = [
      { txt: 'On', val: 'true' },
      { txt: 'Off', val: 'false' }
    ]
    const updatehistorytimeinhours = [
      { txt: '0', val: '0' },
      { txt: '1', val: '1' },
      { txt: '2', val: '2' },
      { txt: '3', val: '3' },
      { txt: '4', val: '4' },
      { txt: '5', val: '5' },
      { txt: '6', val: '6' },
      { txt: '7', val: '7' },
      { txt: '8', val: '8' },
      { txt: '9', val: '9' },
      { txt: '10', val: '10' },
      { txt: '11', val: '11' },
      { txt: '12', val: '12' },
      { txt: '13', val: '13' },
      { txt: '14', val: '14' },
      { txt: '15', val: '15' },
      { txt: '16', val: '16' },
      { txt: '17', val: '17' },
      { txt: '18', val: '18' },
      { txt: '19', val: '19' },
      { txt: '20', val: '20' },
      { txt: '21', val: '21' },
      { txt: '22', val: '22' },
      { txt: '23', val: '23' }

    ]
    const updatehistorytimeinminutes = [
      { txt: '0', val: '0' },
      { txt: '10', val: '10' },
      { txt: '20', val: '20' },
      { txt: '30', val: '30' },
      { txt: '40', val: '40' },
      { txt: '50', val: '50' }
    ]

    return {
      items,
      updatehistorytimeinhours,
      updatehistorytimeinminutes,
      LoginSessionselected: items[0].txt,
      PasswordRulesselected: items[0].txt,
      TemplateMappingselected: items[0].txt,
      labelUpdatedDanger: null,
      SessionTimeout: 0,
      PwPolicyMinimumLength: 0,
      batchPurgeRetentionDays: 0,
      propertyvalue: [],
      updatehistorydeletehours: '00',
      updatehistorydeleteminutes: '00',

      //   foredit
      SessionTimeoutforedit: 0,
      LoginSessionselectedforedit: items[0].txt,
      PasswordRulesselectedrdit: items[0].txt,
      PwPolicyMinimumLengthedit: 0,
      updatehistorydeletehoursedit: '00',
      updatehistorydeleteminutesedit: '00',
      batchPurgeRetentionDaysedit: 0,

      // button disabled
      btnDisabledDashboardSettings: null,

      // Template value
      templatevalue: undefined
    }
  },
  mounted () {
    this.getpropertydetails()
    this.$store.dispatch('auth/getDisabledBtn', '9100').then((flag) => {
      this.btnDisabledDashboardSettings = flag
    })
  },
  methods: {
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    getpropertydetails () {
      const url = '/api/common/property'
      this.$utils.callAxios('get', url).then((res) => {
        if (res.data) {
          this.templatevalue = res.data.templateMappingEnable
          this.propertyvalue = res.data
          if (res.data.aimsDashboardWebCheckpermissionEnable === 'false') {
            this.LoginSessionselected = this.items.find(
              (item) => item.txt === 'Off'
            )
          } else {
            this.LoginSessionselected = this.items.find(
              (item) => item.txt === 'On'
            )
          }
          if (res.data.aimsPortalPwPolicyEnable === 'false') {
            this.PasswordRulesselected = this.items.find(
              (item) => item.txt === 'Off'
            )
          } else {
            this.PasswordRulesselected = this.items.find(
              (item) => item.txt === 'On'
            )
          }
          if (res.data.templateMappingEnable === 'false') {
            this.TemplateMappingselected = this.items.find(
              (item) => item.txt === 'Off'
            )
          } else {
            this.TemplateMappingselected = this.items.find(
              (item) => item.txt === 'On'
            )
          }
          this.SessionTimeout = res.data.aimsDashboardWebSessionTimeout
          this.PwPolicyMinimumLength = res.data.aimsPortalPwPolicyMinimumLength
          this.batchPurgeRetentionDays = res.data.batchPurgeRetentionDays
          const value = (res.data.batchPurgeCron)
          if (res.data.batchPurgeCron.length === 5) {
            this.updatehistorydeletehours = value.split(' ')[0]
            this.updatehistorydeleteminutes = value.split(' ')[1]
          } else {
            this.updatehistorydeletehours = value.split(' ')[2]
            this.updatehistorydeleteminutes = value.split(' ')[1]
          }
          //   foredit
          this.SessionTimeoutforedit = res.data.aimsDashboardWebSessionTimeout
          this.LoginSessionselectedforedit = res.data.aimsDashboardWebCheckpermissionEnable
          this.PasswordRulesselectedrdit = res.data.aimsPortalPwPolicyEnable
          this.PwPolicyMinimumLengthedit = res.data.aimsPortalPwPolicyMinimumLength
          this.batchPurgeRetentionDaysedit = res.data.batchPurgeRetentionDays
          if (res.data.batchPurgeCron.length === 5) {
            this.updatehistorydeletehoursedit = value.split(' ')[0]
            this.updatehistorydeleteminutesedit = value.split(' ')[1]
          } else {
            this.updatehistorydeletehoursedit = value.split(' ')[2]
            this.updatehistorydeleteminutesedit = value.split(' ')[1]
          }
        } else this.areaData = {}
      })
    },
    Toeditpropertyvalueloginsetting () {
      const url = '/api/common/property'
      if (this.SessionTimeout < 60) {
        EventBus.$emit('messageAlert', this.$t('The minimum value is 60 seconds.'))
      } else {
        if (this.LoginSessionselectedforedit === this.LoginSessionselected.val) {
        } else {
          const config = {
            params: {
              key: 'DASHBOARD_WEB_CHECKPERMISSION_ENABLE',
              value: this.LoginSessionselected.val
            }
          }
          const body = {}
          this.$utils.callAxiosWithBody('put', url, body, config).then((res) => {
            EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
            this.getpropertydetails()
          })
        }
        if (this.SessionTimeout === this.SessionTimeoutforedit) {
        } else {
          const config = {
            params: {
              key: 'DASHBOARD_WEB_SESSION_TIMEOUT',
              value: this.SessionTimeout
            }
          }
          const body = {}
          this.$utils.callAxiosWithBody('put', url, body, config).then((res) => {
            EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
            this.getpropertydetails()
          })
        }

        if (this.PasswordRulesselectedrdit === this.PasswordRulesselected.val) {
        } else {
          const config = {
            params: {
              key: 'PORTAL_PW_POLICY_ENABLE',
              value: this.PasswordRulesselected.val
            }
          }
          const body = {}
          this.$utils.callAxiosWithBody('put', url, body, config).then((res) => {
            EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
            this.getpropertydetails()
          })
        }
        if (this.PwPolicyMinimumLengthedit === this.PwPolicyMinimumLength) {
        } else {
          const config = {
            params: {
              key: 'PORTAL_PW_POLICY_MINIMUM_LENGTH',
              value: this.PwPolicyMinimumLength
            }
          }
          const body = {}
          this.$utils.callAxiosWithBody('put', url, body, config).then((res) => {
            EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
            this.getpropertydetails()
          })
        }
      }
    },

    updatehistorydeleteminutes1: String,
    updatehistorydeletehours1: String,
    Toeditpropertyvalueupdatehistory () {
      const url = '/api/common/property'
      if (this.updatehistorydeletehoursedit === this.updatehistorydeletehours) {
      } else {
        if (this.updatehistorydeletehoursedit !== this.updatehistorydeletehours.val && this.updatehistorydeleteminutesedit === this.updatehistorydeleteminutes) {
          this.updatehistorydeleteminutes1 = this.updatehistorydeleteminutes
          this.updatehistorydeletehours1 = this.updatehistorydeletehours.val
        } else {
          this.updatehistorydeleteminutes1 = this.updatehistorydeleteminutes.val
          this.updatehistorydeletehours1 = this.updatehistorydeletehours.val
        }
        const config = {
          params: {
            key: 'BATCH_PURGE_CRON',
            value: '0' + ' ' + this.updatehistorydeleteminutes1 + ' ' + this.updatehistorydeletehours1 + ' ' + '*' + ' ' + '*' + ' ' + '?'
          }
        }
        const body = {}
        this.$utils.callAxiosWithBody('put', url, body, config).then((res) => {
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          this.getpropertydetails()
        })
      }
      if (this.updatehistorydeleteminutesedit === this.updatehistorydeleteminutes) {
      } else {
        if (this.updatehistorydeleteminutesedit !== this.updatehistorydeleteminutes.val && this.updatehistorydeletehoursedit === this.updatehistorydeletehours) {
          this.updatehistorydeleteminutes1 = this.updatehistorydeleteminutes.val
          this.updatehistorydeletehours1 = this.updatehistorydeletehours
        } else {
          this.updatehistorydeleteminutes1 = this.updatehistorydeleteminutes.val
          this.updatehistorydeletehours1 = this.updatehistorydeletehours.val
        }
        const config = {
          params: {
            key: 'BATCH_PURGE_CRON',
            value: '0' + ' ' + this.updatehistorydeleteminutes1 + ' ' + this.updatehistorydeletehours1 + ' ' + '*' + ' ' + '*' + ' ' + '?'
          }
        }
        const body = {}
        this.$utils.callAxiosWithBody('put', url, body, config).then((res) => {
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          this.getpropertydetails()
        })
      }

      if (this.batchPurgeRetentionDaysedit === this.batchPurgeRetentionDays) {
      } else {
        const config = {
          params: {
            key: 'BATCH_PURGE_RETENTION_DAYS',
            value: this.batchPurgeRetentionDays
          }
        }
        const body = {}
        this.$utils.callAxiosWithBody('put', url, body, config).then((res) => {
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
        })
        this.getpropertydetails()
      }
    },
    Toeditpropertyvaluefunctionapproval () {
      const url = '/api/common/property'
      const config = {
        params: {
          key: 'SUPPORT_TEMPLATE_MAPPING_FUNCTION',
          value: this.TemplateMappingselected.val
        }
      }
      const body = {}
      this.$utils.callAxiosWithBody('put', url, body, config).then((res) => {
        EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
        console.log(this.TemplateMappingselected)
        // Template mapping

        var userLevel1 = this.$store.getters['auth/GET_USER_LEVEL']
        console.log(userLevel1)
        if (this.TemplateMappingselected.val === 'false' && userLevel1 === '1') {
          const userLevel = this.$store.state.auth.user.accountInfo.accessMenu
          console.log(userLevel)
          var accessMenu
          accessMenu = userLevel.filter((n) => { return n !== '5300' })
          accessMenu = accessMenu.filter((n) => { return n !== '5200' })
          console.log(accessMenu)
          accessMenu.push('5500')
          this.$store.dispatch('auth/updateAccessMenu', accessMenu)
          this.onSaveMenu(accessMenu, userLevel1)
        }
        if (this.TemplateMappingselected.val === 'true' && userLevel1 === '1') {
          const userLevel = this.$store.state.auth.user.accountInfo.accessMenu
          console.log(userLevel)
          userLevel.splice(5, 0, '5300', '5200')
          // userLevel.push('5300')
          // userLevel.push('5200')
          console.log(userLevel)
          // accessMenu.push('5500')
          this.$store.dispatch('auth/updateAccessMenu', userLevel)
          this.onSaveMenu(userLevel, userLevel1)
        }
        this.getpropertydetails()
      })
    },
    onSaveMenu (menu, level) {
      this.$utils
        .callAxiosWithBody(
          requests.updateAccessMenu.method,
          requests.updateAccessMenu.url,
          menu,
          {
            params: {
              company: this.$store.state.dataStore.selectedStore,
              accessLevel: level
            }
          }
        )
        .then(({ data }) => {
          this.$store.dispatch('auth/updateAccessMenu', menu)
        })
        .catch(error => {
          console.debug(`failed to save menu. erorr: ${error}`)
        })
    }
  }
}
</script>
<style>
.LedPatternClass + input{
  display: contents !important;
}
</style>
